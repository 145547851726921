






























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PackTable extends Vue {
  @Prop({ default: () => [] }) loops!: [];

  get tableHeaders() {
    return [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Category',
        value: 'category.name',
      },
    ];
  }
}
