









import { Component, Prop, Vue } from 'vue-property-decorator';

import { ITag } from '@/interfaces';
import Tag from '@/components/ui/Tag.vue';

@Component({
  components: {
    Tag,
  },
})
export default class Chip extends Vue {
  @Prop({}) list!: ITag[];

  /**
   * Determine whether to render list or not
   */
  get renderList() {
    return this.list.length;
  }
}
