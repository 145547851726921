












































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { KnoxService } from '@/services/knox.service';

import { StripeCheckout } from 'vue-stripe-checkout';
import { CartState } from '@/store/modules/cart';

@Component({
  components: {
    StripeCheckout,
  },
})
export default class CheckoutModal extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop() pack!: any;

  loading = true;
  error = false;
  // pk = '';
  // session = '';

  get successUrl() {
    return `${window.location.href}?ck=true&cid={CHECKOUT_SESSION_ID}`;
  }

  get cancelUrl() {
    return `${window.location.href}?ck=true`;
  }

  async load() {
    // this.loading = true;
    // try {
    //   const priceId =
    //     process.env.NODE_ENV === 'production'
    //       ? this.pack.priceId
    //       : this.pack.priceIdTest;
    //   const { sessionId } = await KnoxService.createItemCheckoutSession(
    //     this.pack._id,
    //     this.$store.state.user.profile.stripeCustomer,
    //     this.successUrl,
    //     this.cancelUrl,
    //     'pack',
    //     priceId,
    //   );
    //   this.session = sessionId;
    // } catch (error) {
    //   this.handleError(error);
    // }

    this.loading = false;
  }

  checkCart() {
    const cart = this.$store.state.cart as CartState;
    const pack = cart.items.find(p => p._id === this.pack._id);

    if (pack) {
      return true;
    }

    return false;
  }

  addToCart() {
    this.$store.dispatch('addToCart', this.pack);
    // this.$emit('toggle');
  }

  removeFromCart() {
    this.$store.dispatch('removeFromCart', this.pack);
    this.$emit('toggle');
  }

  handleError(error: Error) {
    this.$emit('toggle');
    this.$store.dispatch('snackbar/pushError', {
      message: `There was an issue checking out. Please try again in a few moments. Reason: ${error.message}`,
    });
  }

  mounted() {
    // this.pk = process.env.VUE_APP_STRIPEPK;

    if (this.show) {
      this.load();
    }
  }

  @Watch('show')
  onShow(val: boolean, oldVal: boolean) {
    if (val) {
      this.load();
    }
  }
}
