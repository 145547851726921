





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPack } from '@/interfaces/IPack';

import BackButton from '@/components/ui/BackButton.vue';
import TagList from '@/components/ui/TagList.vue';

@Component({
  components: {
    BackButton,
    TagList,
  },
})
export default class PackHeader extends Vue {
  @Prop({ default: () => ({}) }) pack!: IPack;
  @Prop({ default: false }) loading!: boolean;

  showDetails = false;

  get packInCart() {
    return this.$store.getters.packInCart(this.pack._id);
  }

  get headerBgAttrs() {
    return {
      class: 'dn-pack-header__bg',
      style: `
        background-image: url(${this.pack.coverUrl});
      `,
    };
  }

  get formattedPrice() {
    return `$${this.pack.price}`;
  }

  get canDownload() {
    return this.$store.getters['PACK__IN_LIBRARY'];
  }

  get canPurchase() {
    const library = this.$store.getters['USER__LIBRARY'];

    if (!library) {
      return false;
    }

    // check library if pack is in lib already
    let inLibrary = library.packs.find((p: any) => p._id === this.pack._id);

    if (inLibrary) {
      return false;
    }
    // if youre logged in and havent already purchased
    return this.$store.getters['USER__GET_CAN_PURCHASE_PACK'];
  }

  get packPrice() {
    return this.pack.price || 0;
  }

  get showPriceCta() {
    return this.packPrice > 0;
  }

  handleBack() {
    if (this.$route.query['ck']) {
      this.$router.replace({ path: '/browse' });
    } else if (window.history.length < 3) {
      this.$router.push('/');
    } else {
      this.$router.go(-1);
    }
  }
}
