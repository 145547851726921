


































import { NodeService } from '@/services/node.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoopDownloadModal extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({}) loop!: any;
  @Prop({}) variant!: any;
  @Prop({}) pack!: any;

  get loopName() {
    return this.loop?.name || 'Loop';
  }

  get variantName() {
    return this.variant?.name || '';
  }

  get tempoName() {
    return this.variant?.tempo?.name || '';
  }

  get styleName() {
    return this.variant?.style?.name || '';
  }

  get credits() {
    return this.$store.getters['USER__LIBRARY'].credits;
  }

  get midi() {
    return this.loop?.midi.length ? this.loop.midi[0] : {};
  }

  async downloadMidiMap() {
    //open new tab with map url
    const url =
      'https://storage.googleapis.com/drumnow-apps_public/documents/Drumnow_Midi_Maps_2022.zip';

    window.open(url, '_blank');
  }

  async downloadMidi() {
    const canDL = this.$store.getters['USER__CAN_DOWNLOAD_LOOPS'];

    if (!canDL) {
      return;
    }

    if (!this.midi) {
      return;
    }

    const libraryId = this.$store.getters['USER__LIBRARY']._id;
    const data = await NodeService.downloadMidi(
      libraryId,
      this.loop._id,
      this.pack._id,
      this.midi._id,
    );
    const { url, success, message } = data;

    if (!success) {
      this.$store.dispatch('snackbar/pushError', {
        message: `There was an error: ${message}`,
      });
      return;
    }

    this.$emit('refresh');

    const a = document.createElement('a');
    a.href = url;

    a.click();
  }

  async downloadLoop() {
    const canDL = this.$store.getters['USER__CAN_DOWNLOAD_LOOPS'];

    if (!canDL) {
      return;
    }

    const libraryId = this.$store.getters['USER__LIBRARY']._id;
    const data = await NodeService.downloadLoop(
      libraryId,
      this.loop._id,
      this.variant._id,
      this.pack._id,
    );
    const { url, success, message } = data;

    if (!success) {
      this.$store.dispatch('snackbar/pushError', {
        message: `There was an error: ${message}`,
      });
      return;
    }

    this.$emit('refresh');

    const a = document.createElement('a');
    a.href = url;
    a.download = this.loop.name.replace(' ', '') + '.mp3';

    a.click();
  }
}
