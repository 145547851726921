





import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class Chip extends Vue {
  @Prop({ default: 'Chip' }) text!: string;
}
