










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Transport, Player, start, context, Volume } from 'tone';

import { KnoxService } from '@/services/knox.service';

import PackPlaceholder from '@/assets/img/png/loading-pack-image.png';
import PackHeader from '@/components/pack/PackHeader.vue';
import PackTable from '@/components/pack/PackTable.vue';

import { PlayerBus } from '@/components/pack/player/bus';
import { PlayerEvents } from '@/components/pack/player/events';
import { IPack } from '@/interfaces/IPack';

import LoopTable from '@/components/ui/LoopTable.vue';
import CheckoutModal from '@/components/ui/CheckoutModal.vue';
import LoopDownloadModal from '@/components/ui/LoopDownloadModal.vue';
import { NodeService } from '@/services/node.service';
import { NavigationGuardNext, Route } from 'vue-router';

@Component({
  components: {
    CheckoutModal,
    PackHeader,
    PackTable,
    LoopTable,
    LoopDownloadModal,
  },
  data: () => ({
    PackPlaceholder,
  }),
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next();
  },
})
export default class Pack extends Vue {
  @Prop({ default: false }) authorized!: boolean;

  defaultTheme?: any;
  checkout = false;
  checkoutLoading = false;
  loading = true;
  loaded = false;
  downloadLoop = false;

  samplePlayer!: Player;
  sampleVolume!: Volume;

  dlLoading = false;

  currentDlLoop = {};
  currentDlVariant = {};

  mounted() {
    this.defaultTheme = this.$vuetify.theme.currentTheme.primary;
    this.$vuetify.theme.currentTheme.primary = this.pack.accent;

    this.loadData(this.pack._id, this.packAuth, this.toggleLoading);
  }

  toggleLoading() {
    this.loading = !this.loading;
    this.loaded = !this.loaded;
  }

  async loadData(id: string, auth: boolean, next: () => void) {
    return this.$store
      .dispatch('getPackData', { id, auth })
      .then(async (pack: any) => {
        await this.loadPlayer(id, auth);
        next();
      });
  }

  async loadPlayer(id: string, auth: boolean) {
    if (auth) {
      await this.$store.dispatch('getPackLoops', { id });
    }

    this.$emit('load-player', auth);
  }

  get packAuth() {
    return (
      this.$store.getters['USER__CAN_STREAM_LOOPS'] ||
      this.$store.getters['PACK__IN_LIBRARY']
    );
  }

  get pack(): IPack {
    return this.$store.state.pack.pack;
  }

  get auth() {
    return this.$store.state.user.authenticated;
  }

  get isSample() {
    return (
      !this.$store.getters['USER__CAN_STREAM_LOOPS'] &&
      !this.$store.getters['PACK__IN_LIBRARY']
    );
  }

  get userCanDownloadLoops() {
    return this.$store.getters['USER__CAN_DOWNLOAD_LOOPS'];
  }

  get userCanStreamPackLoops() {
    return (
      this.$store.getters['USER__CAN_STREAM_LOOPS'] ||
      this.$store.getters['PACK__IN_LIBRARY']
    );
  }

  get currentLoopItems() {
    if (this.userCanStreamPackLoops) {
      const loops = this.pack.loops;
      // order loops
      // let ordered = new Array(loops.length);
      // loops.forEach((loop, i) => {
      // 	let trackNumber = i;

      // 	if (loop.hasOwnProperty('trackNumber')) {
      // 		trackNumber = loop.trackNumber - 1;
      // 	}
      // 	// subtract one from track no to get array index
      // 	ordered[trackNumber] = loop;
      // });

      // return ordered;
      return loops;
    } else {
      return [
        {
          _id: 'SAMPLE_LOOP',
          loopUrl: this.pack.previewUrl,
          name: 'Sample Loop',
        },
      ];
    }
  }

  get currentPlayerItem() {
    return this.$store.state.player.item;
  }

  get playing() {
    const state = Transport.state;

    return state === 'started';
  }

  async handleClickRow(loop: any) {
    await start();
    // PlayerBus.$emit(PlayerEvents.PLAY_URL, loop._id);
    this.$emit('loop:click', loop);
  }

  async handlePlaySample() {
    await start();

    if (this.samplePlayer && this.samplePlayer.loaded) {
      // stop player
      this.samplePlayer.stop();
      this.samplePlayer.dispose();
      Transport.stop();
      return;
    }

    this.sampleVolume = new Volume(-12).toDestination();
    this.samplePlayer = new Player(this.pack.previewUrl).connect(
      this.sampleVolume,
    );
    this.samplePlayer.autostart = true;
    Transport.start();
    // player.start();
  }

  async handlePurchase() {
    this.checkout = !this.checkout;
  }

  refreshLibrary() {
    this.$store.dispatch('checkForUser');
  }

  handleAddLoop(loop: any) {
    this.currentDlLoop = loop;
    // add the loop variant to the library
    // find the variant with tempo and style
    const currentTempo = this.$store.state.player.tempo;
    const currentStyle = this.$store.state.player.style;
    const variantToAdd = loop.variants.find(
      (variant: any) =>
        variant.tempo._id === currentTempo._id &&
        variant.style._id === currentStyle._id,
    );

    // open confirmation modal
    this.downloadLoop = true;
    this.currentDlVariant = variantToAdd;
  }

  async handleAddToLibrary() {
    this.dlLoading = true;

    try {
      const pid = this.pack._id;
      const lid = this.$store.state.user.profile.library._id;
      const url = await NodeService.downloadPack(pid, lid);
      window.open(url, '_blank');
    } catch (error) {
      await this.$store.dispatch('snackbar/pushError', {
        message: 'Cannot authorize download',
      });
    }

    this.dlLoading = false;
  }

  beforeDestroy() {
    this.$vuetify.theme.currentTheme.primary = this.defaultTheme;
    this.samplePlayer && this.samplePlayer.dispose();
    Transport.stop();
    // destroy the big player
    this.$emit('destroy-player');
  }

  get packLoaded() {
    return this.$store.state.pack.loaded && this.loaded;
  }

  @Watch('packAuth')
  onPackAuth(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.loadData(this.pack._id, newVal, () => ({}));
    }
  }
}
